<template>
  <UiModal
    v-model:open="open"
    :submit-label="$t('collection.modal.create.submit')"
    :title="$t('collection.modal.create.title')"
    @submit="mutate"
  >
    <FormKit
      name="title"
      :label="$t('collection.modal.create.label')"
      :placeholder="$t('collection.modal.create.placeholder')"
      outer-class="$reset"
      validation="required"
    />
  </UiModal>
</template>

<script setup lang="ts">
import {
  CollectionsDocument,
  CreateCollectionDocument,
} from '~/graphql/graphql';
import type {
  CollectionsQuery,
  CreateCollectionMutation,
  CreateCollectionMutationVariables,
} from '~/graphql/graphql';

const open = defineModel<boolean>('open');

const {
  mutate,
  onDone,
  onError,
} = useSofieMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument);

const {client} = useApolloClient();

onDone((result) => {
  const collectionsQuery = {
    query: CollectionsDocument,
  };

  const collections: CollectionsQuery | null = structuredClone(client.cache.readQuery(collectionsQuery));

  if (collections?.collections && result.data?.createCollection) {
    collections.collections.push(result.data.createCollection);

    client.cache.writeQuery({...collectionsQuery, data: collections});
  }

  open.value = false;
});

onError(() => alert('Er zijn fouten opgetreden'));
</script>

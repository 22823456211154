<template>
  <UiModal
    v-model:open="open"
    :cancel-label="false"
    :loading="loading"
    @submit="submit"
    @close="$emit('close')"
  >
    <div
      class="gap grid md:grid-cols-2 md:gap-6"
    >
      <div
        class="
          space-y-6 pb-6
          max-md:mb-6 max-md:border-b max-md:border-gray-200
          md:rounded-lg md:bg-secondary-500 md:p-6
        "
      >
        <h3 class="mb-6">
          {{ $t('collection.modal.add.title') }}
        </h3>

        <slot />
      </div>

      <div class="space-y-6">
        <div class="space-y-2">
          <UiButton
            v-if="status === 'authenticated'"
            :label="$t('collection.modal.add.create_new_collection')"
            type="primaryOutline"
            size="small"
            @click="createCollection = true"
          />

          <template v-else>
            <h4>{{ $t('collection.modal.add.create_new_collection') }}</h4>
            <UiInfo>
              <i18n-t keypath="collection.modal.add.account_cta">
                <template #login>
                  <NuxtLink :to="localePath({name: 'auth-login'})">
                    {{ $t('collection.modal.add.account_cta_login') }}
                  </NuxtLink>
                </template>

                <template #register>
                  <NuxtLink :to="localePath({name: 'subscribe'})">
                    {{ $t('collection.modal.add.account_cta_register') }}
                  </NuxtLink>
                </template>
              </i18n-t>
            </UiInfo>
          </template>
        </div>
        <div class="flex max-h-56 flex-col gap-2 overflow-y-auto">
          <button
            v-for="collection in collections"
            :key="collection.uuid"
            :aria-selected="selectedCollection?.uuid === collection.uuid"
            class="flex items-center gap-4 rounded border border-grey-400 p-1 pr-4 font-medium"
            :class="{
              'bg-secondary-400': selectedCollection?.uuid === collection.uuid,
            }"
            type="button"
            @click="selectedCollection = collection"
          >
            <div class="h-14">
              <NuxtPicture
                v-if="collection.image"
                :alt="collection.image.alt"
                sizes="sm:112px"
                :img-attrs="{'class': 'block h-14 w-14 rounded bg-secondary-500 object-cover'}"
                :src="collection.image.url"
              />
            </div>

            {{ collection.title || $t('collection.default_title') }}

            <template v-if="collection.entries_count">
              ({{ collection.entries_count }})
            </template>

            <UiIcon
              v-if="selectedCollection?.uuid === collection.uuid"
              class="ml-auto text-primary"
              name="Check"
              size="medium"
            />
          </button>
        </div>

        <UiButton
          class="bottom-4 w-full max-md:sticky"
          :disabled="loading || !canAddToCollection"
          :label="$t('collection.modal.add.add_to', {
            collection_type:
              status === 'authenticated'
                ? $t('collection.modal.add.collection_type_collection')
                : $t('collection.modal.add.collection_type_favorites')
          })"
          type="submit"
        />
      </div>
    </div>

    <CollectionModalCreate
      v-model:open="createCollection"
    />
  </UiModal>
</template>

<script setup lang="ts">
import {
  CollectionDocument,
  useAddToCollectionMutation,
  useCollectionsQuery,
} from '~/graphql/graphql';
import type {
  Collection,
  CollectionQuery,
  CollectionQueryVariables,
  Flower,
  FlowerCultivar,
} from '~/graphql/graphql';
import {cloneDeep} from 'lodash-es';
import {useGtm} from '@gtm-support/vue-gtm';

const props = withDefaults(defineProps<{
  flowers?: Array<Pick<Flower, 'uuid' | 'image_primary' | 'title'>>
  flowerCultivars?: Array<Pick<FlowerCultivar, 'uuid' | 'title'>>
  loading?: boolean
}>(), {
  flowers: () => [],
  flowerCultivars: () => [],
  loading: false,
});

const open = defineModel<boolean>('open', {
  required: true,
});

defineEmits<{
  close: []
}>();

const createCollection = ref(false);

const {status} = useAuth();
const selectedCollection = ref<Partial<Collection>>();

const {result, loading: collectionsLoading, refetch: refetchCollections} = useCollectionsQuery();

const collections = computed(() => {
  return result.value?.collections ?? [];
});

watchEffect(() => {
  selectedCollection.value = collections.value[0];
});

const {mutate: addToCollection, loading: mutationLoading, onDone} = useAddToCollectionMutation({
  update(cache, {data}) {
    const collectionQuery = {
      query: CollectionDocument,
      variables: {
        id: status.value === 'authenticated' ? selectedCollection.value?.uuid : undefined,
      },
    };

    const collection: CollectionQuery | null = cloneDeep(cache.readQuery<CollectionQuery, CollectionQueryVariables>(collectionQuery));

    if (!collections.value.length) {
      refetchCollections();
    }

    if (collection?.collection?.entries) {
      data?.addToCollection?.forEach(entry => collection.collection?.entries.push(entry));

      cache.writeQuery({...collectionQuery, data: collection});
    }

    cache.modify({
      id: cache.identify({
        __typename: 'Collection',
        uuid: selectedCollection.value?.uuid,
      }),
      fields: {
        entries_count(value) {
          return value + (data?.addToCollection.length ?? 0);
        },
      },
    });
  },
});

const canAddToCollection = computed(() => !!selectedCollection.value || status.value === 'unauthenticated');

function submit() {
  if (!canAddToCollection.value) {
    return;
  }

  addToCollection({
    id: status.value === 'authenticated' ? selectedCollection.value?.uuid : undefined,
    flowerIds: props.flowers.map(flower => flower.uuid),
    flowerCultivarIds: props.flowerCultivars.map(flowerCultivar => flowerCultivar.uuid),
  });
}

const {notify} = useNotifications();
const {t} = useI18n();
const gtm = useGtm();

onDone(({data}) => {
  if (data?.addToCollection?.length ?? 0 > 0) {
    gtm?.trackEvent({
      event: 'add_to_cart',
      items: gtmSerializeCollectionEntries(data.addToCollection),
    });
  }

  notify({
    text: 'collection.modal.add.notification_added',
    translationParams: {
      count: data?.addToCollection.length ?? 0,
      collection: selectedCollection.value?.title || t('collection.default_title'),
    },
  });

  open.value = false;
});

const loading = computed(() => collectionsLoading.value || mutationLoading.value || props.loading);
</script>
